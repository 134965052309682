const rwdAlert = () => {

    $(window).on('resize', function () {
        validateSizeWindow();
    });

    const alertObj = (text) => {
        return $('<div/>', {
            'class': 'col-12 rwd-alert',
            'text': text,
            'id': 'rwd-alert'
        });
    };

    const validateSizeWindow = () => {
        let currentWindowWidth = window.window.innerWidth;

        if ( currentWindowWidth < 1000) {
            $('.main-container').html(alertObj("Na tym urządzeniu nie możesz rozwiązać testu. Spróbuj na sprzęcie o rozdzielczości większej niż 1000px. Test nie jest przystosowoany do urzadzeń mobilnych takich jak smartfony i tablety"));
        } else {
            if ($('#rwd-alert').length > 0) {
                location.reload();
            }
        }

    };

    validateSizeWindow();
};

export { rwdAlert};

