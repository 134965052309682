
const application = () => {

    // Disable refresh page
    // window.onbeforeunload = function () {return false;}

    const cookieName = "app";
    const basicRoute = "../api/application/";

    let RESULTS = [];  //Array of objects [{qId, aID]
    let questionId, answerId = null;

    var time = null;

    const getToken = () => {
        return window.location.pathname.split("/").pop();
    };

    console.log(getToken());

    // Definition of question object (id + content of the question). i - number of iteration
    const questionObj = (id, text) => {

        let item = $("<li/>", {
            'data-id': id,
            'class': "question",
            'html': $('<div/>', {
                'class': "row",
                'html': $('<div/>', {
                    'class': "col-12 item",
                    'html':  text
                })
            })
        });

        return item;
    };

    const getQuestions = () => {

        $.ajax({
            url: basicRoute + "get-questions/" + getToken(),
            method: "POST",
        })
            .done(function( data ) {
                // reidrect to results
                if (data.redirect) {
                    window.location.replace('../results/'+data.token_c);
                }

                // or load questions
                loadQuestions(data['questions']);
                time = data['time'];

                // Disable loader and show question
                $('.application-loader').addClass('fadeOutUp fast').delay(2000).toggleClass('d-none');
                $('.application-quiz').toggleClass('d-none').addClass('fadeIn');

                slickInit();
                chooseAnswer();
            })

            .fail( function (data, error) {
               console.log(error);
               if (window.location.href != '/') {
                   // window.location.replace('/');
               }
               // exit;
            });

    };

    //Slick definitions
    const slickInit = () => {
        $('.questions').slick({
            slide: 'li',
            dots: false,
            arrows: true,
            infinite: !1,
            adaptiveHeight: !0,
            touchMove: false,
            draggable: false,
            swipe: false,
            swipeToSlide: false,
            accessibility: false,
            prevArrow: false,
            nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow btn btn-primary" aria-label="Następne" role="button" >Następne</button>'
        });

        $('.slick-next').attr('disabled', true);
        $('.slick-next').on('click', function() {
            $(this).attr('disabled', 'disabled');
        });

        setTimeout(
            function()
            {
                timer();
            }, 200);
    };

    const loadQuestions = (data) => {
        console.log(data);
        $(data).each(function (i) {
            let item = $(this)[0];
            let questionEl = questionObj(item.ID, item.content);
            // console.log(item.ID, item.content);
            $('#questions').append(questionEl);
            renderAnswers(questionEl, item['answers']);
        }).ready( function () {
            infobar();
        });


        console.log('Questons loaded');
    };

    // Infor bar (progressbar and another things with state of the test)
    const infobar = () => {
        let numQuestions = $('.question').length;
        const progressContainer = $('.progress');

        $('.slick-next').on('click', function () {
            let selectedAns = $('.answer.selected').length;
            let w = selectedAns * 100 / numQuestions;

            progressContainer.html(progressBarObj(selectedAns, numQuestions, w, false));
        });

        $('#questnum').text(numQuestions);

        progressContainer.append(progressBarObj(0, numQuestions, 0, true));
    };

    //Object HTML - progres bar definition and logic for status of the test
    const progressBarObj = (min, max, w, firstLoad) => {

        const obj = $('<div/>', {
            'class': 'progress-bar bg-color',
            'style': 'width:' + w + '%;',
            // 'text': min + "/" + max + "[" + w + "%]"
            'text': w.toFixed(1) + "%"
        });

        if (firstLoad) {
            obj.removeClass('bg-color');
        }

        if (w >= 100) {
            window.onbeforeunload = null;

            obj.addClass('bg-success');
            $('#questions').addClass('animated fadeOut');
            $('#box-success').show().addClass('fadeIn');

            saveAnswer();
        }

        return obj;
    };

    // Sybols for answers
    const ANSWERS = ['A', 'B', 'C', 'D'].reverse();

    //Object HTML - Answer (once item)
    const answerObj = (id, text, i) => {

        let item = $("<div/>", {
            'data-id': id,
            'class': "answer",
            'html': "<span>" + ANSWERS[i] + "</span>" + " " + text
        });

        return item;
    };


    const renderAnswers = (parentElement, data) => {
        $(data).each( function (index) {
            let id = $(this)[0].ID;
            let text = $(this)[0].content;
            parentElement.find('p').last().after(answerObj(id, text, index));
        });
    };


    // Logic for select answer
    const chooseAnswer = () => {
        // $('.answer').click(function () { console.log($(this).data('id'))});
        $('.answer').click(function () {
            $('.answer').each(function() {
                $(this).removeClass('checked');
            });
            $(this).addClass('checked selected');

            questionId = $(this).parents('li').data('id');
            answerId = $(this).data('id');

            $('.slick-next').attr('disabled', false);
        });

        // Save answer
        $('.slick-next').click(function () {
            // RESULTS.push({'qID':questionId, 'aID':answerId});
            RESULTS.push([questionId, answerId]);
        });
    };

    if (Cookies.get(cookieName) === undefined && window.location.href.indexOf('test') > 0 ) {
        // Cookies.set(cookieName, Date.now());
        getQuestions();
    }


    const saveAnswer = () => {
        calcResolvingTime();

        // Ajax request
        $.ajax({
            url: basicRoute + "save-results/" + getToken(),
            type: "POST",
            dataType: 'json',
            data: JSON.stringify(RESULTS),
            success: function(response){
                checkTest(response.tc, response.tt);
            },
            fail: function (response, error) {
                console.log(error);
            }

        });
        // console.log(JSON.stringify(RESULTS));
    }

    const checkTest = (tc, tt) => {
        $.ajax({
            url: basicRoute + "check-test/" + tc + '/' + tt,
            type: "POST",
            dataType: 'json',
            success: function(response){

                if (response == 1) { //passed
                    window.location.replace('/test/' + getToken());
                } else { // no passed
                    window.location.replace('/results/' + getToken());
                }

            },
            fail: function (response, error) {
                console.log(error);
            }
        });
    }


    const timer = () => {

        let timer2 = time + ":00";
        let interval = setInterval(function () {

            let timer = timer2.split(':');
            //by parsing integer, I avoid all extra string processing
            let minutes = parseInt(timer[0], 10);
            let seconds = parseInt(timer[1], 10);
            --seconds;
            minutes = (seconds < 0) ? --minutes : minutes;
            if (minutes < 0) clearInterval(interval);
            seconds = (seconds < 0) ? 59 : seconds;
            seconds = (seconds < 10) ? '0' + seconds : seconds;


            if (seconds == 59 && minutes == 0) {
                $('.timer').addClass('pulse');
            }

            if (seconds == 15 && minutes == 0) {
                $('.timer').addClass('timer--red');
            }

            if (seconds == 0 && minutes == 0) {
                $('.timer').addClass('fadeOut').removeClass('infinite');

                //Action for timeout
               progressBarObj(0, 0, 100, false); // finishing test
            }

            $('.timer').html(minutes + ':' + seconds);
            timer2 = minutes + ':' + seconds;

        }, 1000);
    };

    const calcResolvingTime = () => {
        let currTimerVal = $('.timer').text().split(':');
        let dt = (time * 60) - ( (parseInt(currTimerVal[0]) * 60) + parseInt(currTimerVal[1]) );

        RESULTS.push(['dt', dt]);
    };


    $('#box-success').hide();

    $('#message').on('change', function() {
        $(this).fadeOut();
    });
};

export { application };