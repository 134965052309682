// Libraries

// Partials
import { application } from "./partials/application";
import  { rwdAlert} from "./partials/rwd_alert";

$(document).ready(function () {

    const Site = {
        init: function () {
            rwdAlert();
            application();
        },
    };
    Site.init();

    // $( window ).resize(function() {
    //
    // });

});


new WOW().init();



